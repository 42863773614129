import { type FC, type ReactNode } from 'react';
import { type Environment } from 'react-relay';
import useFetchViewer from './useFetchViewer';

import { PhoneNumber } from './PhoneNumber';
import { type FocusEventHandler, type Country } from './types';

type Props = {
    phoneNumber?: string;
    environment: Environment;
    countryCode?: string;
    autoFocus?: boolean;
    dataTn: string;
    errorDataTn?: string;
    name?: string;
    errorMessage?: ReactNode | string;
    hasError?: boolean;
    disabled?: boolean;
    size?: 'small' | 'medium' | 'large';
    onBlur?: FocusEventHandler;
    onFocus?: FocusEventHandler;
    onChange?: FocusEventHandler;
    placeholder?: string;
    type?: string;
    wrapperClass?: string;
    hasValidText?: boolean;
    label?: React.ReactNode;
    flagWrapperPosition?: string;
    errorMessageAlignment?: string;
    countryName?: string;
};

const PhoneNumberWrapper: FC<Props> = props => {
    const { environment, countryCode, phoneNumber, countryName } = props;
    const { viewer, isLoading } =
        useFetchViewer({
            environment,
            input: { countryCode, phoneNumber },
        }) || {};
    const { geography, parsedPhoneNumber } = viewer || {};
    const { countriesCallingCodes: countriesCodes } = geography || {};
    const countryCodeFromName = (countriesCodes || []).find(
        (country: Country) => country?.name?.toLowerCase() === countryName?.toLowerCase()
    );
    return (
        <PhoneNumber
            {...{
                ...props,
                countriesCodes,
                parsedPhoneNumber,
                isLoading,
                countryCode: countryCodeFromName?.abbreviation || countryCode || '',
            }}
        />
    );
};

export default PhoneNumberWrapper;

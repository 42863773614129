import { useState, useEffect } from 'react';
import { fetchQuery_DEPRECATED as fetchQuery, graphql, type Environment } from 'react-relay/legacy';

import {
    type useFetchViewerQuery$data,
    type useFetchViewerQuery,
} from './__generated__/useFetchViewerQuery.graphql';

type Viewer = useFetchViewerQuery$data['viewer'];

const viewerQuery = graphql`
    query useFetchViewerQuery(
        $phoneNumber: String!
        $countryCode: String
        $parsePhoneNumber: Boolean!
    ) {
        viewer {
            geography {
                countriesCallingCodes {
                    name
                    countryCallingCode
                    abbreviation
                }
            }
            parsedPhoneNumber(phoneNumber: $phoneNumber, countryCode: $countryCode)
                @include(if: $parsePhoneNumber) {
                isValid
                countryCode
                countryCallingCode
                internationalNumber
                number
            }
        }
    }
`;

const useFetchViewer = ({
    environment,
    input,
}: {
    environment: Environment;
    input: { phoneNumber?: string; countryCode?: string };
}): { viewer: Viewer | undefined; isLoading: boolean } => {
    const [viewer, setViewer] = useState<Viewer>();
    const [isLoading, setIsLoading] = useState(false);
    const { phoneNumber, countryCode } = input || {};
    useEffect(() => {
        async function fetchData(): Promise<void> {
            // it will be parsing phoneNumber only on initial load if phoneNumber and coutry code is passed
            const result = await fetchQuery<useFetchViewerQuery>(environment, viewerQuery, {
                phoneNumber: phoneNumber || '',
                countryCode,
                parsePhoneNumber: !!phoneNumber,
            });
            setViewer(result?.viewer);
            setIsLoading(false);
        }
        if (!viewer) {
            setIsLoading(true);
            fetchData();
        }
    }, [environment, viewer, phoneNumber, countryCode]);
    return { viewer, isLoading };
};

export default useFetchViewer;
